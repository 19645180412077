import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    CardContent,
    CardMedia,
    Modal,
    Paper,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import Schedule from '@mui/icons-material/Schedule';
import {
    Visibility as VisibilityIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import { getUserVideo, userVideoUpdate, userVideoDelete, userVideoProcessKeypoints, listWaveTypes } from '../../utils/apiRoutes';
import { userData } from '../../utils/userData';
import { createNotification } from '../../utils/alerts';
import { StatusEnum } from '../../enums/status';
import styles from './details.module.scss';
import { translateStatus } from '../../enums/status';
import { translateWaveSide, WaveSideEnum } from '../../enums/waveSide';
import { UserTypeEnum } from '../../enums/userType';

import moment from 'moment';

const Input = styled('input')({
    display: 'none',
});

const DetailsVideo = () => {
    const { videoId } = useParams();
    const isMobile = window.innerWidth < 1000;
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [keypointsLoading, setKeypointsLoading] = useState(false);
    const [user] = useState(userData());
    const [video, setVideo] = useState(null);
    const [open, setOpen] = useState(false);
    const [waveTypeId, setWaveTypeId] = useState(0);
    const [waveTypeModelId, setWaveTypeModelId] = useState(0);
    const [waveTypesArr, setWaveTypesArr] = useState([]);
    const [waveSide, setWaveSide] = useState(WaveSideEnum.LEFT);
    let intervalRef = useRef(null);

    const getVideoInformation = async (videoId, reprocess = false) => {
        try {
            const response = await getUserVideo(user.id, videoId);
            setVideo(response);

            if (!reprocess) {
                setTitle(response.title);
                setWaveTypeId(response.wave_type_id ?? 0);
                setWaveTypeModelId(response.wave_type_model_id ?? 0);
                setWaveSide(response.wave_side);
            }

            if (response.keypoints_status != StatusEnum.COMPLETED && response.keypoints_status != StatusEnum.FAILED) {
                startPolling();
            }
        } catch (error) {
            createNotification('error', 'Erro ao buscar informações do vídeo.', isMobile);
        }
    }

    useEffect(() => {
        if (videoId !== undefined) {
            getVideoInformation(videoId);
        }
    }, [videoId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await userVideoUpdate(user.id, videoId, {
                title: title,
                waveTypeId: waveTypeId,
                waveTypeModelId: waveTypeModelId == 0 ? undefined : waveTypeModelId,
                waveSide: waveSide
            });

            createNotification('success', 'Vídeo atualizado com sucesso!', isMobile);
        } catch (error) {
            createNotification('error', 'Erro ao atualizar informações. Por favor, tente novamente.', isMobile);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmDelete = async () => {
        setDeleteLoading(true);
        handleClose();

        try {
            await userVideoDelete(user.id, videoId);

            createNotification('success', 'Vídeo excluído com sucesso!', isMobile);
            setTimeout(() => {
                window.location.href = "/my-videos"
            }, 1500);
        } catch (error) {
            setDeleteLoading(false);
            createNotification('error', 'Erro ao excluir vídeo. Por favor, tente novamente.', isMobile);
        }
    };

    const handleReprocessKeypoints = async () => {
        setKeypointsLoading(true);

        try {
            await userVideoProcessKeypoints({ videoId });

            startPolling();
        } catch (error) {
            createNotification('error', 'Erro ao reprocessar keypoints. Por favor, tente novamente.', false);
            setKeypointsLoading(false);
        }
    };

    const startPolling = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(async () => {
            setKeypointsLoading(false);
            const updatedVideo = await getUserVideo(user.id, videoId);
            setVideo(prevVideo => ({
                ...prevVideo,
                keypoints_status: updatedVideo.keypoints_status,
                keypoints_progress_percentage: updatedVideo.keypoints_progress_percentage,
                video_keypoints_link: updatedVideo.video_keypoints_link,
                updated_at: updatedVideo.updated_at
            }));

            if (updatedVideo.keypoints_status === StatusEnum.COMPLETED || updatedVideo.keypoints_status === StatusEnum.FAILED) {
                clearInterval(intervalRef.current);
            }
        }, 5000);
    };

    const handleCompareButton = () => {
        localStorage.setItem('comparedVideoId', videoId);
        window.location.href = '/analysis/compare'
    }

    useEffect(() => {
        getWaveTypes();
    }, []);

    const getWaveTypes = async () => {
        try {
            const response = await listWaveTypes();
            setWaveTypesArr(response.wave_types);
        } catch (error) {
            createNotification('error', 'Erro ao buscar os tipos de onda.', isMobile);
        }
    }

    const handleWaveSideChange = (event) => {
        setWaveSide(event.target.value);
    };

    const handleWaveTypeChange = (event) => {
        setWaveTypeId(event.target.value);

        if (event.target.value == 0) setWaveTypeModelId(0);
    };

    const handleWaveTypeModelChange = (event) => {
        setWaveTypeModelId(event.target.value);
    };

    return (
        <>
            <Navbar />
            <Box
                className={styles["main-container"]}
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="center"
                alignItems="start"
                minHeight="80vh"
                p={2}
                gap={2}
            >
                <Box
                    className={styles["video-section"]}
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    p={2}
                    boxShadow={3}
                    borderRadius={2}
                    bgcolor="white"
                >
                    <CardContent>
                        <Typography variant="h6">Vídeo original</Typography>
                        <CardMedia component="video" controls src={video ? video.video_link : ''}>
                            <source type="video/mp4" />
                        </CardMedia>
                    </CardContent>
                    <CardContent>
                        <Typography variant="h6">Vídeo com keypoints</Typography>
                        <CardMedia component="video" controls src={video && video.keypoints_status === StatusEnum.COMPLETED ? video.video_keypoints_link : ''}>
                            <source type="video/mp4" />
                        </CardMedia>
                    </CardContent>
                </Box>
                <Box
                    className={styles["form-section"]}
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    p={2}
                    boxShadow={3}
                    borderRadius={2}
                    bgcolor="white"
                >
                    <Typography variant="h6" align="center">Informações Gerais</Typography>
                    <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2} flexGrow={1}>
                        <TextField
                            label="Título"
                            variant="outlined"
                            value={title ? title : ''}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            required
                        />
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="wave-type-select-label">Tipo de onda</InputLabel>
                                        <Select
                                            labelId="wave-type-select-label"
                                            id="wave-type"
                                            value={waveTypeId}
                                            label="Tipo de onda"
                                            onChange={handleWaveTypeChange}
                                        >
                                            <MenuItem key={'0'} value={0}>
                                                Não selecionado
                                            </MenuItem>
                                            {waveTypesArr && waveTypesArr.map((value) => (
                                                <MenuItem key={value.id} value={value.id}>
                                                    {value.name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>

                                {user && user.user_type !== UserTypeEnum.STUDENT && (
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="wave-type-select-label">Linha de onda</InputLabel>
                                            <Select
                                                labelId="wave-type-select-label"
                                                id="wave-type"
                                                value={waveTypeModelId}
                                                label="Linha de onda"
                                                onChange={handleWaveTypeModelChange}
                                                disabled={waveTypeId == 0}
                                            >
                                                <MenuItem key={'0'} value={0}>
                                                    Não selecionado
                                                </MenuItem>
                                                {waveTypeId > 0 && waveTypesArr[waveTypeId] && [
                                                    <MenuItem key={'1'} value={1}>
                                                        {waveTypesArr[waveTypeId + 1].model_1}
                                                    </MenuItem>,
                                                    <MenuItem key={'2'} value={2}>
                                                        {waveTypesArr[waveTypeId + 1].model_2}
                                                    </MenuItem>
                                                ]}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="wave-side-select-label">Lado da onda</InputLabel>
                                        <Select
                                            labelId="wave-side-select-label"
                                            id="wave-side"
                                            value={waveSide}
                                            label="Lado da onda"
                                            onChange={handleWaveSideChange}
                                        >
                                            {Object.keys(WaveSideEnum).map((key) => (
                                                <MenuItem key={key} value={WaveSideEnum[key]}>
                                                    {translateWaveSide(WaveSideEnum[key])}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={styles["info-container"]}>
                            <Box className={styles["date"]}>
                                <Schedule />
                                <Typography variant="body2">Criado em {video ? moment(video.created_at).format('DD/MM/YYYY [às] HH:mm:ss') : ''}</Typography>
                            </Box>
                            <Box className={styles["date"]}>
                                <Schedule />
                                <Typography variant="body2">Atualizado em {video ? moment(video.updated_at).format('DD/MM/YYYY [às] HH:mm:ss') : ''}</Typography>
                            </Box>
                            {video?.keypoints_status === StatusEnum.TO_PROCESS && (
                                <Box className={`${styles["status"]} ${styles["to-process"]}`}>
                                    <HourglassEmpty />
                                    <Typography variant="body2">Aguardando para gerar keypoints</Typography>
                                </Box>
                            )}
                            {video?.keypoints_status === StatusEnum.PROCESSING && (
                                <Box className={`${styles["status"]} ${styles["processing"]}`}>
                                    <CircularProgress size="1.5em" />
                                    <Typography variant="body2">Gerando keypoints ({video.keypoints_progress_percentage | 0}%)</Typography>
                                </Box>
                            )}
                            {video?.keypoints_status === StatusEnum.COMPLETED && (
                                <Box className={`${styles["status"]} ${styles["completed"]}`}>
                                    <TaskAltIcon />
                                    <Typography variant="body2">Keypoints processados</Typography>
                                </Box>
                            )}
                            {video?.keypoints_status === StatusEnum.FAILED && (
                                <Box className={`${styles["status"]} ${styles["failed"]}`}>
                                    <CancelIcon />
                                    <Typography variant="body2">Erro ao gerar keypoints</Typography>
                                </Box>
                            )}
                        </Box>
                        <Box mt="auto" display="flex" flexDirection="column" gap={2}>
                            {video?.keypoints_status === StatusEnum.FAILED && (
                                <Button
                                    variant="outlined"
                                    disabled={keypointsLoading}
                                    endIcon={keypointsLoading && <CircularProgress sx={{ color: "white" }} size={20} />}
                                    className={styles["keypoints-button"]}
                                    onClick={() => handleReprocessKeypoints()}
                                >
                                    Reprocessar keypoints
                                </Button>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={loading || deleteLoading || keypointsLoading}
                                endIcon={loading && <CircularProgress sx={{ color: "white" }} size={20} />}
                                className={styles["confirm-button"]}
                            >
                                Salvar
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleDeleteClick}
                                disabled={loading || deleteLoading || keypointsLoading || video?.keypoints_status == StatusEnum.PROCESSING}
                                endIcon={deleteLoading && <CircularProgress sx={{ color: "red" }} size={20} />}
                            >
                                Excluir Vídeo
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box
                    className={styles["comparison-section"]}
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    p={2}
                    boxShadow={3}
                    borderRadius={2}
                    bgcolor="white"
                >
                    <Typography variant="h6" align="center">Análises</Typography>
                    <Box className={styles["comparison-container"]} flexGrow={1}>
                        {video?.student_analysis.length === 0 && !loading && (
                            <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                                Não há registros
                            </Typography>
                        )}
                        {video?.student_analysis.map(analysis => (
                            <Paper key={analysis.id} className={styles["analysis-card"]}>
                                <Box className={styles["analysis-card-info"]}>
                                    <Typography variant="h6">{analysis.title}</Typography>
                                    <Typography variant="body2">Status: {translateStatus(analysis.status)}</Typography>
                                    <Typography variant="body2">Criada em: {moment(analysis.created_at).format('DD/MM/YYYY [às] HH:mm:ss')}</Typography>
                                    <Typography variant="body2">Atualizada em: {moment(analysis.updated_at).format('DD/MM/YYYY [às] HH:mm:ss')}</Typography>
                                </Box>
                                <IconButton className={styles["show-button"]} aria-label="edit" onClick={() => { window.location.href = `/analysis/${analysis.id}` }}>
                                    <VisibilityIcon />
                                </IconButton>
                            </Paper>
                        ))}
                    </Box>
                    <Button
                        variant="outlined"
                        className={styles["compare-button"]}
                        disabled={loading || deleteLoading || keypointsLoading || video?.keypoints_status == StatusEnum.PROCESSING}
                        onClick={() => { handleCompareButton() }}
                    >
                        Comparar
                    </Button>
                </Box>
            </Box >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles["modal-box"]}>
                    <Typography className={styles["modal-title"]}>
                        {video?.student_analysis.length !== 0 ? <>As análises feitas com esse vídeo também serão excluídas!<br /></> : ''}
                        Você realmente deseja excluir este registro?
                    </Typography>
                    <Box className={styles["modal-buttons"]}>
                        <Button onClick={handleClose} className={styles["cancel-button"]}>
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirmDelete} className={styles["confirm-button"]}>
                            Excluir
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    );
};

export default DetailsVideo;
