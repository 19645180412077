import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid
} from '@mui/material';
import { VideoLibrary } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../../components/Navbar";
import { listWaveTypes, userVideoRegister } from '../../utils/apiRoutes';
import { userData } from '../../utils/userData';
import { createNotification } from '../../utils/alerts';
import './register.scss';
import { translateWaveSide, WaveSideEnum } from '../../enums/waveSide';
import { UserTypeEnum } from '../../enums/userType';

const Input = styled('input')({
    display: 'none',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: '#003057',
    '&:hover': {
        backgroundColor: 'transparent',
        color: '#004074',
    },
    '& .MuiTypography-root': {
        marginLeft: theme.spacing(1),
        color: '#003057',
        transition: 'color 0.3s ease',
    },
    '&:hover .MuiTypography-root': {
        color: '#004074',
    },
}));

const RegisterVideo = () => {
    const isMobile = window.innerWidth < 768;
    const [title, setTitle] = useState('');
    const [waveTypeId, setWaveTypeId] = useState(0);
    const [waveTypesArr, setWaveTypesArr] = useState([]);
    const [waveTypeModelId, setWaveTypeModelId] = useState(0);
    const [waveSide, setWaveSide] = useState(WaveSideEnum.LEFT);
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(userData());

    const handleVideoChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setVideoFile(file);
            const url = URL.createObjectURL(file);
            setVideoPreview(url);
        }
    };

    const clearVideo = () => {
        setTimeout(() => {
            setVideoFile(null);
            setVideoPreview(null);
        }, 500);
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (videoFile == null) {
            createNotification('error', 'Selecione um arquivo de vídeo.', isMobile);
            return;
        }

        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('waveTypeId', waveTypeId);
            formData.append('waveSide', waveSide);
            formData.append('file', videoFile);

            if (waveTypeModelId != 0) formData.append('waveTypeModelId', waveTypeModelId);

            const response = await userVideoRegister(user.id, formData);

            createNotification('success', 'Vídeo cadastrado com sucesso!', isMobile);

            setTimeout(() => {
                window.location.href = `/my-videos/${response.id}`;
            }, 2000);
        } catch (error) {
            createNotification('error', 'Erro ao realizar cadastro. Por favor, tente novamente.', isMobile);
            setLoading(false);
        }
    };

    useEffect(() => {
        getWaveTypes();
    }, []);

    const getWaveTypes = async () => {
        try {
            const response = await listWaveTypes();
            setWaveTypesArr(response.wave_types);
        } catch (error) {
            createNotification('error', 'Erro ao buscar os tipos de onda.', isMobile);
        }
    }

    const handleWaveSideChange = (event) => {
        setWaveSide(event.target.value);
    };

    const handleWaveTypeChange = (event) => {
        setWaveTypeId(event.target.value);

        if (event.target.value == 0) setWaveTypeModelId(0);
    };

    const handleWaveTypeModelChange = (event) => {
        setWaveTypeModelId(event.target.value);
    };

    return (
        <>
            <Navbar />
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Box className="video-box"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    boxShadow={3}
                    p={4}
                    borderRadius={2}
                    sx={{ width: { xs: '80%', sm: '70%', md: '50%', lg: '40%', xl: '40%' } }}
                >
                    <Typography variant="h5" component="div" mb={2} align="center">
                        Cadastrar Vídeo
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        display="flex"
                        flexDirection="column"
                        width="100%"
                    >
                        <TextField
                            label="Título"
                            variant="outlined"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            margin="normal"
                            fullWidth
                            required
                            style={{ marginBottom: '5%' }}
                        />
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="wave-type-select-label">Tipo de onda</InputLabel>
                                        <Select
                                            labelId="wave-type-select-label"
                                            id="wave-type"
                                            value={waveTypeId}
                                            label="Tipo de onda"
                                            onChange={handleWaveTypeChange}
                                        >
                                            <MenuItem key={'0'} value={0}>
                                                Não selecionado
                                            </MenuItem>
                                            {waveTypesArr && waveTypesArr.map((value) => (
                                                <MenuItem key={value.id} value={value.id}>
                                                    {value.name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>

                                {user && user.user_type !== UserTypeEnum.STUDENT && (
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="wave-type-select-label">Linha de onda</InputLabel>
                                            <Select
                                                labelId="wave-type-select-label"
                                                id="wave-type"
                                                value={waveTypeModelId}
                                                label="Linha de onda"
                                                onChange={handleWaveTypeModelChange}
                                                disabled={waveTypeId == 0}
                                            >
                                                <MenuItem key={'0'} value={0}>
                                                    Não selecionado
                                                </MenuItem>
                                                {waveTypeId > 0 && waveTypesArr[waveTypeId] && [
                                                    <MenuItem key={'1'} value={1}>
                                                        {waveTypesArr[waveTypeId + 1].model_1}
                                                    </MenuItem>,
                                                    <MenuItem key={'2'} value={2}>
                                                        {waveTypesArr[waveTypeId + 1].model_2}
                                                    </MenuItem>
                                                ]}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="wave-side-select-label">Lado da onda</InputLabel>
                                        <Select
                                            labelId="wave-side-select-label"
                                            id="wave-side"
                                            value={waveSide}
                                            label="Lado da onda"
                                            onChange={handleWaveSideChange}
                                        >
                                            {Object.keys(WaveSideEnum).map((key) => (
                                                <MenuItem key={key} value={WaveSideEnum[key]}>
                                                    {translateWaveSide(WaveSideEnum[key])}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
                            {videoPreview && (
                                <video width="100%" controls>
                                    <source src={videoPreview} type="video/mp4" />
                                    Seu navegador não suporta o elemento de vídeo.
                                </video>
                            )}
                            <label htmlFor="video-upload">
                                <Input
                                    accept="video/*"
                                    id="video-upload"
                                    type="file"
                                    onChange={handleVideoChange}
                                    onClick={() => { clearVideo() }}
                                />
                                <StyledIconButton component="span">
                                    <VideoLibrary />
                                    <Typography variant="h6" component="span">
                                        Selecione o arquivo de vídeo
                                    </Typography>
                                </StyledIconButton>
                            </label>
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 2 }}
                            disabled={loading}
                            endIcon={loading && <CircularProgress size={20} />}
                            className="confirm-button"
                        >
                            Cadastrar
                        </Button>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
        </>
    );
};

export default RegisterVideo;
