import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    CardContent,
    CardMedia,
    Modal,
    Tabs,
    Tab
} from '@mui/material';
import Schedule from '@mui/icons-material/Schedule';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useLocation } from "react-router-dom";
import { getUserVideo, getInstructorVideo, getAnalysis, deleteAnalysis, updateAnalysis } from '../../utils/apiRoutes';
import { userData } from '../../utils/userData';
import { createNotification } from '../../utils/alerts';
import { StatusEnum } from '../../enums/status';
import './compared-videos-boxes.scss';
import AnalysisTabPanel from '../AnalysisTab';

import moment from 'moment';

const ComparedVideosBoxes = ({ title = null, setTitle = null }) => {
    const { analysisId } = useParams();
    const isMobile = window.innerWidth < 1000;
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [analysisLoading, setAnalysisLoading] = useState(false);
    const [user] = useState(userData());
    const [video, setVideo] = useState(null);
    const [instructorVideo, setInstructorVideo] = useState(null);
    const [open, setOpen] = useState(false);
    let intervalRef = useRef(null);
    const [analysis, setAnalysis] = useState(null);
    const [analysisTitle, setAnalysisTitle] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [movimentosText, setMovimentosText] = useState('');
    const [anglesText, setAnglesText] = useState('');
    const [sugestoesText, setSugestoesText] = useState('');

    const getStudentVideoInformation = async (videoId) => {
        try {
            const response = await getUserVideo(user.id, videoId);
            setVideo(response);
        } catch (error) {
            createNotification('error', 'Erro ao buscar informações do vídeo do aluno.', isMobile);
        }
    }

    const getInstructorVideoInformation = async (videoId) => {
        try {
            const response = await getInstructorVideo(videoId);
            setInstructorVideo(response);

            if (analysisId === undefined)
                setTitle(`Comparação com: ${response.user_name} - ${response.title}`)
        } catch (error) {
            console.log("error", error)
            createNotification('error', 'Erro ao buscar informações do vídeo do instrutor.', isMobile);
        }
    }

    const getAnalysisInformation = async (analysisId) => {
        try {
            setAnalysisLoading(true);
            const response = await getAnalysis(analysisId);

            getStudentVideoInformation(response.student_video_id)
            getInstructorVideoInformation(response.instructor_video_id);

            setAnalysis(response);
            setAnalysisTitle(response.title);

            if (response.status === StatusEnum.TO_PROCESS || response.status === StatusEnum.PROCESSING) {
                startPolling(analysisId);
            } else {
                if (response.status === StatusEnum.FAILED) {
                    let errorMessage = `<strong><span style='color: red'>Houve uma falha ao realizar a análise</span></strong>`
                    document.getElementById("analysis-description").innerHTML = formatDescription(errorMessage);
                } else {
                    const { movimentos, sugestoes, angles } = parseContent(response.description);
                    setMovimentosText(formatDescription(movimentos));
                    setSugestoesText(formatDescription(sugestoes));
                    setAnglesText(angles);
                }
                setAnalysisLoading(false);

                if (!response.ghost_video_link) {
                    startPollingGhost(analysisId);
                }
            }
        } catch (error) {
            createNotification('error', 'Erro ao buscar informações da análise.', isMobile);
            setTimeout(() => {
                window.location.href = "/analysis"
            }, 1500);
        }
    }

    const formatDescription = (description) => {
        return description.replace(/\n/g, '<br/>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    }

    useEffect(() => {
        if (analysisId !== undefined) {
            getAnalysisInformation(analysisId);
        } else {
            const studentVideoId = localStorage.getItem('comparedVideoId');
            const instructorVideoId = localStorage.getItem('instructorVideoId');

            if (studentVideoId)
                getStudentVideoInformation(studentVideoId);

            if (instructorVideoId)
                getInstructorVideoInformation(instructorVideoId);
        }
    }, [analysisId]);

    const handleUpdateAnalysis = async () => {
        setLoading(true);

        if (analysisTitle === '') {
            createNotification('error', 'É obrigatório enviar um título.', isMobile);
            setLoading(false);
            return;
        }

        try {
            await updateAnalysis(analysisId, {
                title: analysisTitle
            });

            createNotification('success', 'Análise atualizada com sucesso!', isMobile);
        } catch (error) {
            createNotification('error', 'Erro ao atualizar informações. Por favor, tente novamente.', isMobile);
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmDelete = async () => {
        setDeleteLoading(true);
        handleClose();

        try {
            await deleteAnalysis(analysisId);

            createNotification('success', 'Análise excluída com sucesso!', isMobile);
            setTimeout(() => {
                window.location.href = "/analysis"
            }, 1500);
        } catch (error) {
            setDeleteLoading(false);
            createNotification('error', 'Erro ao excluir análise. Por favor, tente novamente.', isMobile);
        }
    };

    const startPolling = (analysisId) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(async () => {
            const updatedAnalysis = await getAnalysis(analysisId);
            setAnalysis(prevAnalysis => ({
                ...prevAnalysis,
                status: updatedAnalysis.status,
                updated_at: updatedAnalysis.updated_at,
                ghost_video_link: updatedAnalysis.ghost_video_link
            }));

            if (updatedAnalysis.status === StatusEnum.COMPLETED || updatedAnalysis.status === StatusEnum.FAILED) {
                clearInterval(intervalRef.current);
                if (updatedAnalysis.status === StatusEnum.FAILED) {
                    let errorMessage = `<strong><span style='color: red'>Houve uma falha ao realizar a análise</span></strong>`
                    document.getElementById("analysis-description").innerHTML = formatDescription(errorMessage);
                } else {
                    const { movimentos, sugestoes } = parseContent(updatedAnalysis.description);
                    startDescription(formatDescription(movimentos), setMovimentosText);
                    startDescription(formatDescription(sugestoes), setSugestoesText);
                }

                setAnalysisLoading(false);

                if (!updatedAnalysis.ghost_video_link) {
                    startPollingGhost(analysisId);
                }
            }
        }, 5000);
    };

    const startPollingGhost = (analysisId) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(async () => {
            const updatedAnalysis = await getAnalysis(analysisId);
            setAnalysis(prevAnalysis => ({
                ...prevAnalysis,
                updated_at: updatedAnalysis.updated_at,
                ghost_video_link: updatedAnalysis.ghost_video_link
            }));

            if (updatedAnalysis.ghost_video_link) {
                clearInterval(intervalRef.current);
            }
        }, 5000);
    };

    const startDescription = (description, setText) => {
        const speed = 20; // lower is faster
        let index = 0;
        let currentText = '';

        const typeWriter = () => {
            if (index < description.length) {
                currentText += description[index];
                setText(currentText); // Atualiza o estado em vez de usar o innerHTML
                index++;
                setTimeout(typeWriter, speed);
            }
        };

        setText(''); // Limpa o texto inicial
        typeWriter();
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    // Separar movimentos e sugestões
    const parseContent = (description) => {
        const movimentos = description.match(/<manobras>(.*?)<\/manobras>/s)?.[1] || '';
        const sugestoes = description.match(/<posturas>(.*?)<\/posturas>/s)?.[1] || '';
        const angles = description.match(/<angles>(.*?)<\/angles>/s)?.[1] || '';
        return { movimentos, sugestoes, angles };
    };

    return (
        <>
            <Box
                className="main-container"
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="center"
                alignItems="start"
                minHeight="80vh"
                p={2}
                gap={2}
            >
                <Box
                    className="video-section"
                    flexGrow={2}
                    flexBasis={isMobile ? '100%' : '66.67%'}
                    flexShrink={1}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    p={2}
                    boxShadow={3}
                    borderRadius={2}
                    bgcolor="white"
                    minHeight={isMobile ? 'auto' : '100vh'}
                    maxHeight={isMobile ? 'none' : '100vh'}
                >
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2} alignItems="center">
                        {analysisId !== undefined && (
                            <TextField
                                label="Título"
                                variant="standard"
                                value={analysisTitle}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setAnalysisTitle(e.target.value)}
                                fullWidth={isMobile}
                                required
                                style={{ flexGrow: 1 }}
                            />
                        )}
                        {analysisId === undefined && (
                            <TextField
                                label="Título"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                value={title ? title : ''}
                                onChange={(e) => setTitle(e.target.value)}
                                fullWidth={isMobile}
                                required
                                style={{ flexGrow: 1 }}
                            />
                        )}

                        {analysisId !== undefined && !isMobile && (
                            <Box display="flex" gap={1}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={loading || deleteLoading}
                                    endIcon={loading && <CircularProgress sx={{ color: "white" }} size={20} />}
                                    className='confirm-button'
                                    onClick={() => { handleUpdateAnalysis() }}
                                >
                                    Salvar
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleDeleteClick}
                                    disabled={loading || deleteLoading || analysis?.status === StatusEnum.PROCESSING}
                                    endIcon={deleteLoading && <CircularProgress sx={{ color: "red" }} size={20} />}
                                >
                                    Excluir Análise
                                </Button>
                            </Box>
                        )}
                    </Box>

                    {analysisId !== undefined && (
                        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1} mt={isMobile ? 2 : 0}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Schedule />
                                <Typography variant="body2">
                                    Criado em {analysis ? moment(analysis.created_at).format('DD/MM/YYYY [às] HH:mm:ss') : ''}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Schedule />
                                <Typography variant="body2">
                                    Atualizado em {analysis ? moment(analysis.updated_at).format('DD/MM/YYYY [às] HH:mm:ss') : ''}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {isMobile && analysisId !== undefined && (
                        <Box display="flex" gap={1} flexDirection="column" mt={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={loading || deleteLoading}
                                endIcon={loading && <CircularProgress sx={{ color: "white" }} size={20} />}
                                className="confirm-button"
                                fullWidth
                                onClick={() => { handleUpdateAnalysis() }}
                            >
                                Salvar
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleDeleteClick}
                                disabled={loading || deleteLoading || analysis?.status === StatusEnum.PROCESSING}
                                endIcon={deleteLoading && <CircularProgress sx={{ color: "red" }} size={20} />}
                                fullWidth
                            >
                                Excluir Análise
                            </Button>
                        </Box>
                    )}

                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                        <CardContent flex={1}>
                            <Typography variant="h6">Seu vídeo: {video ? video.title : ''}</Typography>
                            <CardMedia component="video" src={(analysis && analysis.ghost_video_link ? analysis.ghost_video_link : (video ? video.video_keypoints_link : ''))} controls autoPlay loop>
                                <source type="video/mp4" />
                            </CardMedia>
                        </CardContent>
                        <CardContent flex={1}>
                            <Typography variant="h6">Seu vídeo em keypoints</Typography>
                            <CardMedia component="video" src={video ? video.skeleton_video_link : ''} controls autoPlay loop>
                                <source type="video/mp4" />
                            </CardMedia>
                        </CardContent>
                    </Box>

                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                        <CardContent flex={1}>
                            <Typography variant="h6">Vídeo do instrutor: {instructorVideo ? instructorVideo.title : ''}</Typography>
                            <CardMedia component="video" src={instructorVideo ? instructorVideo.video_keypoints_link : ''} controls autoPlay loop>
                                <source type="video/mp4" />
                            </CardMedia>
                        </CardContent>
                        <CardContent flex={1}>
                            <Typography variant="h6">Vídeo do instrutor em keypoints</Typography>
                            <CardMedia component="video" src={instructorVideo ? instructorVideo.skeleton_video_link : ''} controls autoPlay loop>
                                <source type="video/mp4" />
                            </CardMedia>
                        </CardContent>
                    </Box>
                </Box>

                {analysisId !== undefined && (
                    <Box
                        className="comparison-section"
                        flexGrow={1}
                        flexBasis={isMobile ? '100%' : '33.33%'}
                        flexShrink={1}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        p={2}
                        boxShadow={3}
                        borderRadius={2}
                        bgcolor="white"
                        minHeight={isMobile ? 'auto' : '100vh'}
                        maxHeight={isMobile ? 'none' : '100vh'}
                        overflow={"auto"}
                        style={!analysisLoading ? { justifyContent: 'flex-start' } : { alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <Typography variant="h6" align="center">Análise da comparação</Typography>
                        <Box className="comparison-container" flexGrow={1} overflow={analysisLoading ? 'none' : 'auto'}>
                            {!analysisLoading ? (
                                <>
                                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="comparison tabs">
                                        <Tab label="Posturas" />
                                        <Tab label="Movimentos" />
                                        <Tab label="Ângulos" />
                                    </Tabs>

                                    <AnalysisTabPanel value={tabValue} index={0}>
                                        <div dangerouslySetInnerHTML={{ __html: sugestoesText }} />
                                    </AnalysisTabPanel>

                                    <AnalysisTabPanel value={tabValue} index={1}>
                                        <div dangerouslySetInnerHTML={{ __html: movimentosText }} />
                                    </AnalysisTabPanel>

                                    <AnalysisTabPanel value={tabValue} index={2}>
                                        <div dangerouslySetInnerHTML={{ __html: anglesText }} />
                                    </AnalysisTabPanel>
                                </>
                            ) : (
                                <Typography variant="body2" align="center">
                                    Analisando... <CircularProgress size="1em" />
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-box">
                    <Typography className="modal-title">Você realmente deseja excluir este registro?</Typography>
                    <Box className="modal-buttons">
                        <Button onClick={handleClose} className="cancel-button">
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirmDelete} className="confirm-button">
                            Excluir
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ComparedVideosBoxes;
